import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import theme from '../../styles';
// import { NavLink } from 'react-router-dom';
import PrimaryButton from '../PrimaryButton';

const CookiesConsent = ({ position }) => {
  const [showConsent, setShowConsent] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    const consent = localStorage.getItem('cookies-consent');
    if (!consent) {
      setShowConsent(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookies-consent', 'accepted');
    setShowConsent(false);
  };

  if (!showConsent) {
    return null;
  }


  return (
    <MainContainer position={position} scrollPosition={scrollPosition}>
      <Agreement>
        <H1>Usamos cookies para mejorar tu experiencia</H1>
        <PrimaryButton onClick={handleAccept} backgroundColor={theme.color.blue} padding={'10px 20px'} fontSize='14px'>Aceptar cookies</PrimaryButton>

      </Agreement>
      <Legal>
        <Link href='/privacy-notice'>Aviso de Privacidad</Link>
        <Link href='/terms-and-conditions'>Términos y Condiciones</Link>
      </Legal>
    </MainContainer>
  );
};

const MainContainer = styled('div')(({ position, scrollPosition }) => ({
  position: 'sticky',
  bottom: 0,
  width: '100%',
  padding: 20,
  boxSizing: 'border-box',
  backgroundColor: 'rgba(236, 240, 241, 0.9)',
  backdropFilter: position === 'sticky' || scrollPosition !== 0 ? 'blur(10px)' : 'none',
  zIndex: 100,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 20,
}));

const H1 = styled('h1')(() => ({
  color: theme.color.textPrimary,
  fontFamily: theme.fontFamily.primary,
  fontSize: 20,
}));

const Agreement = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 20,
  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },
}));

const Legal = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 20,
  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },
}));

const Link = styled('a')(() => ({
  color: theme.color.textPrimary,
  transition: 'all 0.3s ease',
  '&:hover': {
    color: theme.color.primary,
    fontWeight: 'bold',
  },
}));

export default CookiesConsent;
