import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import theme from '../styles';
import { NavLink, useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PrimaryButton from '../components/PrimaryButton';
import Lottie from 'lottie-react';
import useGlobalData from '../context/useGlobalData';
import { useScroll } from '../components/ScrollContext';
import LoadingAnimation from '../assets/lottie/Animation - 1719783273631.json';

const Hero = () => {
    const { memberships, areas, clases, events, home, products, contactForm, faq } = useScroll();
    const globalData = useGlobalData();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (globalData) {
            setLoading(false);
        }
    }, [globalData]);

    const settings = {
        dots: false,
        arrows: false,
        swipe: false,
    };

    const scrollToSection = (sectionRef) => {
        window.scrollTo({
            top: sectionRef.current.offsetTop - 73,
            behavior: 'smooth'
        });
    };

    const handleNavClick = (link) => {
        const sectionName = link.startsWith("#") ? link.substring(1) : null;
        if (sectionName && sectionRefs[sectionName]) {
            scrollToSection(sectionRefs[sectionName]);
        } else {
            navigate(link);
        }
    };

    const sectionRefs = {
        memberships,
        areas,
        clases,
        events,
        home,
        products,
        contactForm,
        faq,
    };

    if (loading) {
        return (
            <LoadingContainer>
                <Lottie animationData={LoadingAnimation} loop={true} style={{ maxWidth: '200px', margin: 50 }} />
            </LoadingContainer>
        );
    }

    const { title, heroBackground, heroButton } = globalData;

    return (
        <MainContainer ref={home}>
            <Slides {...settings}>
                <SlideContainer>
                    <Slide1 backgroundImage={`${process.env.REACT_APP_API_URL}${heroBackground.data.attributes.url}`}>
                        <H1>{title}</H1>
                        {heroButton && (
                            <ButtonsContainer>
                                {heroButton.map((item, index) => (
                                    item.Link.includes("#") ? (
                                        <NavLink key={index} to="#" onClick={() => handleNavClick(item.Link)}>
                                            <PrimaryButton
                                                backgroundColor={index === 0 ? theme.color.blue : theme.color.accent}
                                                color={index === 0 ? theme.color.light : theme.color.textPrimary}
                                                padding="20px 90px"
                                            >
                                                {item.Name}
                                            </PrimaryButton>
                                        </NavLink>
                                    ) : (
                                        <NavLink key={index} to={item.Link}>
                                            <PrimaryButton
                                                backgroundColor={index === 0 ? theme.color.blue : theme.color.accent}
                                                color={index === 0 ? theme.color.light : theme.color.textPrimary}
                                                padding="20px 90px"
                                            >
                                                {item.Name}
                                            </PrimaryButton>
                                        </NavLink>
                                    )
                                ))}
                            </ButtonsContainer>
                        )}
                    </Slide1>
                </SlideContainer>
                <SlideContainer>
                    <Slide1>
                        <NavLink to="/" onClick={() => handleNavClick(memberships)}>
                            <PrimaryButton backgroundColor={theme.color.blue} color={theme.color.light} padding='20px 90px'>
                                Membresías
                            </PrimaryButton>
                        </NavLink>
                        <NavLink to="/login">
                            <PrimaryButton backgroundColor={theme.color.accent} color={theme.color.textPrimary} padding='20px 90px'>
                                Iniciar sesión
                            </PrimaryButton>
                        </NavLink>
                    </Slide1>
                </SlideContainer>
            </Slides>
        </MainContainer>
    );
};

const LoadingContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
}));

const MainContainer = styled('div')(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const Slides = styled(Slider)(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
}));

const SlideContainer = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const Slide1 = styled('div')(({backgroundImage}) => ({
    width: '100%',
    height: '91vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 100,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    '::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    '> *': {
        zIndex: 2,
    },
}));

const ButtonsContainer = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 100,
    [`@media (max-width:${theme.breakpoints.values.md}px)`]: {
        flexDirection: 'column',
        gap: 30,
    },
}));

const H1 = styled('h1')(() => ({
    fontFamily: theme.fontFamily.tertiary,
    fontWeight: 'bold',
    fontSize: '50px',
    color: theme.color.light,
    textShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
}));

export default Hero;
