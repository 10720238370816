import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import ClasesCard from '../../components/clasesCard';
import { getEvent } from '../../api/event';
import { useScroll } from '../../components/ScrollContext';

const Events = () => {
  const { events } = useScroll();
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await getEvent();
        if (response && response.data) {
          setEventData(response.data);
        }
      } catch (error) {
        console.error('Error fetching event data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) {
    return <div ref={events}>Loading...</div>;
  }

  if (!eventData.length) {
    return <div ref={events}>No events available</div>;
  }

  return (
    <ClasesContainer ref={events}>
      <ClasesContent>
        <Title>Eventos</Title>
        <ClasesGrid>
          {eventData.map((event) => {
            const { id, attributes } = event;
            const imageUrl = `${process.env.REACT_APP_API_URL}${attributes.image.data.attributes.url}`;
            const instagramLink = attributes.instagramLink.Link;

            return (
              <a key={id} href={instagramLink} target="_blank" rel="noopener noreferrer">
                <ClasesCard img={imageUrl} />
              </a>
            );
          })}
        </ClasesGrid>
      </ClasesContent>
    </ClasesContainer>
  );
};

const ClasesContainer = styled('div')(() => ({
  padding: '20px 0',
  width: '100%',
}));

const ClasesContent = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  gap: 60,
}));

const Title = styled('h1')(() => ({
  fontFamily: 'Inter, sans-serif',
  fontSize: '32px',
  fontWeight: 'bold',
  color: 'black',
}));

const ClasesGrid = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '20px',
  justifyItems: 'center',
  width: '100%',
  maxWidth: '1200px',

  '@media (max-width: 900px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  '@media (max-width: 600px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '15px',
  },

  '@media (max-width: 300px)': {
    gridTemplateColumns: '1fr',
  },
}));

export default Events;
