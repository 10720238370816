import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import ClasesCard from '../components/clasesCard';
import { getClass } from '../api/class';
import { useScroll } from '../components/ScrollContext';

const Clases = () => {
  const { clases } = useScroll();
  const [classData, setClassData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await getClass();
        if (response && response.data) {
          setClassData(response.data);
        }
      } catch (error) {
        console.error('Error fetching class data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClasses();
  }, []);

  if (loading) {
    return <div ref={clases}>Loading...</div>;
  }

  if (!classData.length) {
    return <div ref={clases}>No classes available</div>;
  }

  return (
    <ClasesContainer ref={clases}>
      <ClasesContent>
        <Title>Clases</Title>
        <ClasesGrid>
          {classData.map((clase) => {
            const { id, attributes } = clase;
            const { title, description, image } = attributes;
            const imageUrl = `${process.env.REACT_APP_API_URL}${image.data.attributes.formats.thumbnail.url}`;

            return (
              <ClasesCard
                key={id}
                title={title}
                text={description}
                img={imageUrl}
              />
            );
          })}
        </ClasesGrid>
      </ClasesContent>
    </ClasesContainer>
  );
};

const ClasesContainer = styled('div')(() => ({
  backgroundColor: '#FAD749',
  padding: '200px 0',
  clipPath: 'polygon(0 25%, 100% 0, 100% 75%, 0 100%)',
  [`@media (max-width: 1290px)`]: {
    clipPath: 'polygon(0 15%, 100% 0, 100% 85%, 0 100%)',
  },
  [`@media (max-width:680px)`]: {
    clipPath: 'polygon(0 5%, 100% 0, 100% 95%, 0 100%)',
  },
}));

const ClasesContent = styled('div')(() => ({
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  textAlign: 'center',
}));

const Title = styled('h1')(() => ({
  fontFamily: 'Inter, sans-serif',
  fontSize: '32px',
  fontWeight: 'bold',
  color: 'black',
  marginBottom: '40px',
}));

const ClasesGrid = styled('div')(() => ({
  display: 'flex',
  gap: '80px',
  justifyContent: 'center',
  flexWrap: 'wrap',
  '@media (max-width: 900px)': {
    gap: '30px',
  },
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default Clases;
