import React from 'react';
import { styled } from '@mui/material';
import PrimaryButton from '../../components/PrimaryButton'
import theme from '../../styles'
import Lottie from "lottie-react";
import ClimbingShoes from '../../assets/lottie/Animation - 1717795484768.json';
import triangle from '../../assets/triangle.svg';

const NoMatch = () => {
  return (
    <NoMatchContainer>
      <ContentContainer>
      <LottieStyled animationData={ClimbingShoes} loop={true} />

      <Message>Lo siento, no pudimos encontrar lo que buscas</Message>
      <SubMessage>Pero no te preocupes, hay muchas más cosas que ver en nuestra página principal</SubMessage>
      <PrimaryButton
        backgroundColor={theme.color.blue}
        color={ theme.color.light }
        padding="10px 20px"
      >
        Volver a la página principal
      </PrimaryButton>
      </ContentContainer>
      <Image src={triangle} alt='triangle-on-background' />

    </NoMatchContainer>
  );
};

const Image = styled('img')({
  width: '100%',
  marginBottom: '-1px',
});

const NoMatchContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  color: '#333',
  

}));

const ContentContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  color: '#333',
  gap: 30,
  padding: 20

}));

const ErrorCode = styled('h1')(() => ({
  fontSize: '8rem',
  margin: '0',
  color: '#ff5722',
  '@media (max-width: 900px)': {
    fontSize: '6rem',
  },
  '@media (max-width: 600px)': {
    fontSize: '4rem',
  },
}));

const Message = styled('h1')(() => ({
  fontSize: '2rem',
  margin: '10px 0',
  fontWeight: 'bold',
  '@media (max-width: 900px)': {
    fontSize: '1.8rem',
  },
  '@media (max-width: 600px)': {
    fontSize: '1.5rem',
  },
}));

const SubMessage = styled('h2')(() => ({
  fontSize: '1.5rem',
  fontWeight: 'normal',
  margin: '5px 0',
  '@media (max-width: 900px)': {
    fontSize: '1.2rem',
  },
  '@media (max-width: 600px)': {
    fontSize: '1rem',
  },
}));

const LottieStyled = styled(Lottie)(() => ({
  maxWidth: '400px',
  [`@media (max-width:600px)`]: {
    maxWidth: '200px',
  },
}));

const HomeButton = styled('a')(() => ({
  marginTop: '20px',
  padding: '10px 20px',
  fontSize: '1rem',
  color: '#fff',
  backgroundColor: '#007bff',
  textDecoration: 'none',
  borderRadius: '5px',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
  '@media (max-width: 600px)': {
    fontSize: '0.9rem',
    padding: '8px 16px',
  },
}));

export default NoMatch;
