import React, { useState, useEffect, forwardRef } from 'react';
import emailjs from 'emailjs-com';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Swal from 'sweetalert2';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { styled } from '@mui/material';
import theme from '../../styles';
import triangle from '../../assets/triangle-b.svg';
import PrimaryButton from '../../components/PrimaryButton';
import IconAndButton from '../../components/IconAndButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Lottie from "lottie-react";
import ClimbingShoes from '../../assets/lottie/Climbing Shoes.json';
import { useScroll } from '../../components/ScrollContext';
import useGlobalData from '../../context/useGlobalData'

const ContactSection = forwardRef((props, ref) => {
  const { contactForm } = useScroll();
  const globalData = useGlobalData();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    category: 'consulta-general', // Default category
  });
  const [recaptcha, setRecaptcha] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600); // Change the threshold based on your desired breakpoint
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = process.env.REACT_APP_EMAIL_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
    const userID = process.env.REACT_APP_EMAIL_USER_ID;

    if (!recaptcha) {
      console.log('Recaptcha failed');
      return;
    }

    emailjs
      .sendForm(serviceID, templateID, e.target, userID)
      .then((response) => {
        console.log('Email sent successfully:', response);
        setFormData({
          name: '',
          email: '',
          message: '',
          category: 'consulta-membresias',
        });
        Swal.fire('¡Éxito!', 'Email enviado correctamente', 'success');
        window.gtag('event', 'email_sent', {
          event_category: 'Contact Form',
          event_label: 'Email Sent',
          env: process.env.REACT_APP_ENV
        });
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  const handleWhatsAppClick = () => {
    if (!globalData) { return null }
    const { whatsappLink } = globalData;

    window.open(whatsappLink.Link, '_blank');
  };

  const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  if (!reCaptchaKey) {
    console.error("reCaptcha key is not defined");
    return null; // or render some fallback UI
  }

  const inputStyle = {
    width: '80%',
    backgroundColor: 'white',
    margin: 10,
    borderRadius: '10px',
  };

  return (
    <MainContainer ref={contactForm}>
      <Image src={triangle} alt='triangle-on-background' />
      <ContactFormContainer>
        <H1>Contáctanos</H1>
        <ContentContainer isSmallScreen={isSmallScreen}>
          <ImageContainer>
            <LottieStyled animationData={ClimbingShoes} loop={true} />
          </ImageContainer>

          <FormContainer onSubmit={handleSubmit}>
            <CustomTextField
              select
              label='Categoría'
              variant='filled'
              id='category'
              name='category'
              required
              value={formData.category}
              onChange={handleChange}
              style={inputStyle}
            >
              <MenuItem value='consulta-membresias'>
                Consulta sobre Membresías
              </MenuItem>
              <MenuItem value='consulta-general'>Consulta general</MenuItem>
              <MenuItem value='consulta-clases'>Consulta sobre clases</MenuItem>
              <MenuItem value='consulta-eventos'>Consulta sobre eventos</MenuItem>
              <MenuItem value='comentarios-sugerencias'>
                Comentarios y sugerencias
              </MenuItem>
              <MenuItem value='reserva-grupo'>Reserva de grupo</MenuItem>
              <MenuItem value='consulta-horarios'>Consulta sobre horarios</MenuItem>
              <MenuItem value='equipamiento-ropa'>Equipamiento y ropa</MenuItem>
            </CustomTextField>
            {formData.category === 'resume' && (
              <CategoryNote>
                Si quieres enviar tu currículum en PDF, por favor mándalo a
                <a href='mailto:bouldergrid@eddy.mx' className='App-link'>
                  bouldergrid@eddy.mx
                </a>
                , pero también puedes usar este formulario para agregar un enlace de Google Drive 😉
              </CategoryNote>
            )}
            <CustomTextField
              label='Nombre'
              variant='filled'
              type='text'
              id='name'
              name='name'
              value={formData.name}
              onChange={handleChange}
              required
              style={inputStyle}
            />
            <CustomTextField
              label='Email'
              variant='filled'
              type='email'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              required
              style={inputStyle}
            />
            <CustomTextField
              label='Mensaje'
              variant='filled'
              id='message'
              name='message'
              value={formData.message}
              onChange={handleChange}
              required
              multiline
              maxRows={4}
              style={inputStyle}
            />
            <PrimaryButton
              variant='contained'
              type='submit'
              width='80%'
              marginTop='10px'
              backgroundColor={theme.color.accent}
              color={theme.color.textPrimary}
            >
              Enviar mensaje
            </PrimaryButton>
            <IconAndButton
              variant='contained'
              type='button'
              width='80%'
              marginTop='30px'
              backgroundColor={theme.color.green}
              color={theme.color.light}
              onClick={handleWhatsAppClick} // Set up action for the button
              icon={() => (
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  size='xl'
                />
              )}
            >
              O envíanos una pregunta
            </IconAndButton>

            <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
              <GoogleReCaptcha onVerify={() => setRecaptcha(true)} />
            </GoogleReCaptchaProvider>
          </FormContainer>
        </ContentContainer>
      </ContactFormContainer>
      <ImageFlipped src={triangle} alt='triangle-on-background' />
    </MainContainer>
  );
});

const MainContainer = styled('div')(() => ({
  width: '100%',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}));

const ContactFormContainer = styled('div')(() => ({
  boxSizing: 'border-box',
  backgroundColor: theme.color.blue,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '50px',
  '@media (max-width: 600px)': {
    padding: '30px 10px',
  },
}));

const H1 = styled('h1')(() => ({
  marginBottom: '24px',
  fontFamily: theme.fontFamily.primary,
  fontWeight: 'bold',
  color: theme.color.light,
}));

const LottieStyled = styled(Lottie)(() => ({

  maxWidth: '500px',
  [`@media (max-width:600px)`]: {
    maxWidth: '200px',
  },

}));

const ContentContainer = styled('div')(({ isSmallScreen }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: isSmallScreen ? 'column' : 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

const ImageContainer = styled('div')(() => ({
  alignSelf: 'center',
}));

const FormContainer = styled('form')({
  width: '100%',
  maxWidth: '600px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
});

const CustomTextField = styled(TextField)({
  '& .MuiFilledInput-root': {
    width: '100%',
    borderRadius: '10px',
    backgroundColor: 'white',
    transition: 'box-shadow 0.3s ease-in-out',
  },
  '& .MuiFilledInput-root:focus-within, & .MuiFilledInput-root.Mui-focused': {
    boxShadow: '0 0 0 4px #919191, 0 0 0 6px #2cdce6',
  },
  '& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after': {
    borderBottom: 'none',
  },
  '& .MuiFilledInput-root:hover': {
    backgroundColor: '#ECECEC',
  },
  '& .MuiFilledInput-underline:hover:before': {
    borderBottom: 'none !important',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#2cdce6',
  },
});

const CategoryNote = styled('p')(() => ({
  color: 'white',
  margin: '10px',
  padding: '0 50px',
  fontSize: '12px',
}));

const Image = styled('img')({
  width: '100%',
  marginBottom: '-1px',
});

const ImageFlipped = styled('img')({
  width: '100%',
  transform: 'rotate(180deg)',
  marginTop: '-1px',
});

export default ContactSection;
