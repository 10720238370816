import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import theme from '../styles';
import Area from '../components/area';
import { getArea } from '../api/area';
import { useScroll } from '../components/ScrollContext';

const Areas = () => {
  const { areas } = useScroll();
  const [areaData, setAreaData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAreas = async () => {
      try {
        const response = await getArea();
        if (response && response.data) {
          setAreaData(response.data);
        }
      } catch (error) {
        console.error('Error fetching area data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAreas();
  }, []);

  if (loading) {
    return <div ref={areas}>Loading...</div>;
  }

  if (!areaData.length) {
    return <div ref={areas}>No areas available</div>;
  }

  return (
    <MainContainer ref={areas}>
      <Title>&Aacute;reas</Title>
      <AreaGrid>
        {areaData.map((area) => {
          const { id, attributes } = area;
          const { title, image } = attributes;
          const imageUrl = `${process.env.REACT_APP_API_URL}${image.data.attributes.formats.thumbnail.url}`;

          return <Area key={id} text={title} img={imageUrl} />;
        })}
      </AreaGrid>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  padding: '60px 0px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 30,
}));

const Title = styled('h1')(() => ({
  marginBottom: '24px',
  fontFamily: theme.fontFamily.primary,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));

const AreaGrid = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '32px',
  justifyContent: 'center',
  justifyItems: 'center',
  '@media (max-width: 1400px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@media (max-width: 822px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

export default Areas;