import React from 'react'
import relizMobile from '../../assets/reliz-vector-mobile.svg'
import relizDesktop from '../../assets/reliz-vector-desktop.svg'
import boulderGridLogo from '../../assets/boulder-grid-logotype.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import useGlobalData from '../../context/useGlobalData'

const Footer = ({ children }) => {
  const globalData = useGlobalData();

  if (!globalData) {
    return <div>Loading...</div>; // Show a loading message while fetching data
  }

  const { whatsappLink, instagramLink } = globalData;
  return (
    <div
      className={"footer-main-container"}
    >
      <img src={relizMobile} className="footer-vector block md:hidden" alt="reliz-vector-mobile" />
      <img src={relizDesktop} className="footer-vector hidden md:block" alt="reliz-vector-desktop" />
      <div className='footer-sub-container bg-[#1F4447] p-4 flex flex-col items-center'>
        <a href='/' className='hover'>
          <img src={boulderGridLogo} className='logotype' alt='reliz-vector' />
        </a>
        <div className='footer-items-container flex flex-col' style={{ textAlign: 'center' }}>
          <a href='/privacy-notice' className='hover'>
            Aviso de privacidad
          </a>
          <a href='/terms-and-conditions' className='hover'>
            Términos y condiciones
          </a>
        </div>
        <div className='social-media-container flex flex-row'>
          <a href={instagramLink.Link} target='_blank' rel='noopener noreferrer' className='hover'>
            <FontAwesomeIcon
              icon={faInstagram}
              style={{
              }}
              size='xl'
            />
          </a>
          <a href={whatsappLink.Link} target='_blank' rel='noopener noreferrer' className='hover'>
            <FontAwesomeIcon
              icon={faWhatsapp}
              style={{
              }}
              size='xl'
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer



