import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import './faq.css';
import triangle from '../../assets/triangle.svg';
import faqData from './faq.json'; // Ensure faq.json has the same format as globalData.faq
import Lottie from "lottie-react";
import ClimbingHolds from '../../assets/lottie/Climbing Holds.json';
import ClimbingHelmet from '../../assets/lottie/Climbing Helmet.json';
import useGlobalData from '../../context/useGlobalData';
import { useScroll } from '../../components/ScrollContext';

const FAQ = () => {
  const { faq } = useScroll();
  const globalData = useGlobalData();
  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (index) => {
    setExpandedSection((prev) => (prev === index ? null : index));
  };

  if (!globalData) {
    return <p ref={faq}>Loading...</p>;
  }

  return (
    <div
      style={{
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      ref={faq}
    >
      <Image src={triangle} alt='triangle-on-background' />
      <FAQContainer>
        <Lottie animationData={ClimbingHolds} loop={true} style={{ maxWidth: '200px', margin: 50 }} />
        <Main>
          <H1>Preguntas frecuentes</H1>
          <ul className="tota11y-accordion" aria-label="FAQs accordion">
            {globalData.faq.map((item, index) => (
              <li
                key={item.id}
                className={expandedSection === index ? 'expanded' : ''}
              >
                <button
                  type="button"
                  onClick={() => toggleSection(index)}
                  style={{
                    padding: '2rem 4.5rem',
                    display: 'block',
                    color: '#151618',
                    position: 'relative',
                    cursor: 'pointer',
                    outline: 'transparent',
                    textDecoration: 'none',
                    background: 'none',
                    border: 'none',
                    width: '100%',
                    textAlign: 'left',
                    fontWeight: 'bold'  // Aquí aplicamos negritas
                  }}
                >
                  {item.question}
                </button>
                <section
                  className={`content ${expandedSection === index ? 'visible' : ''}`}
                  id={`acc${index}`}
                  aria-labelledby={`#accLink${index}`}
                  tabIndex="0"
                >
                  <p>{item.answer}</p>
                </section>
              </li>
            ))}
          </ul>
        </Main>
        <Lottie animationData={ClimbingHelmet} loop={true} style={{ maxWidth: '240px', margin: 10 }} />
      </FAQContainer>
    </div>
  );
};

const Main = styled('main')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}));

const H1 = styled('h1')(() => ({
  color: 'white',
  fontWeight: 700,
  padding: 20,
  fontSize: 30,
}));

const FAQContainer = styled('div')(() => ({
  width: '100%',
  boxSizing: 'border-box',
  padding: '10px',
  backgroundColor: "#0F1B20",
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  [`@media (max-width:600px)`]: {
    flexDirection: 'column',
  },
}));

const Image = styled('img')({
  width: '100%',
  marginBottom: '-1px',
});

export default FAQ;
