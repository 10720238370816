import AuthProvider from './auth/authProvider';
import './App.css';
import Navbar from './components/Navbar';
import Router from './routes';
import Footer from './sections/Footer/index.jsx';
import { ScrollProvider } from './components/ScrollContext.jsx';
import GlobalDataProvider from './context/globalDataProvider.js';

import CookiesConsent from './components/CookiesConsent';

function App() {
  return (
    <AuthProvider>
      <GlobalDataProvider>
        <ScrollProvider>
          <Navbar />
          <Router />
          <Footer />
          <CookiesConsent />
        </ScrollProvider>
      </GlobalDataProvider>
    </AuthProvider>
  );
}

export default App;
