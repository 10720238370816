import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import './contactInfo.css';
import faqData from './contactInfo.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
// import theme from '../../styles'
import Lottie from "lottie-react";
import ClimbingShoes from '../../assets/lottie/Climbing Chalk Bag.json';
import useGlobalData from '../../context/useGlobalData'

const ContactInfo = () => {
  const [expandedSection, setExpandedSection] = useState(null);
  const [currentDay, setCurrentDay] = useState('');
  const [morningSchedule, setMorningSchedule] = useState('');
  const [afternoonSchedule, setAfternoonSchedule] = useState('');
  const globalData = useGlobalData();
  const formatTime = (time) => time ? new Date(`1970-01-01T${time}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'Cerrado';

  useEffect(() => {
    const daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    const getMSTDate = () => {
      const date = new Date();
      const utcTime = date.getTime() + date.getTimezoneOffset() * 60000;
      const mstTime = new Date(utcTime + 3600000 * -7); // Convert UTC to GMT-7 (MST)
      return mstTime;
    };
    const today = getMSTDate().getDay();
    setCurrentDay(daysOfWeek[today]);

    if (globalData) {
      getFullSchedule();
    }
  }, [globalData, currentDay]);

  if (!globalData) {
    return <div>Loading...</div>; // Show a loading message while fetching data
  }

  const { addressLink, contactEmail, phoneNumber, scheduleDescription, schedule } = globalData;


  const toggleSection = (index) => {
    setExpandedSection((prev) => (prev === index ? null : index));
  };

  const getCurrentSchedule = () => {
    const daySchedule = faqData.faq[0].days.find(day => day.day === currentDay);
    return daySchedule ? daySchedule.schedule : 'Horario no disponible';
  };

  const getFullSchedule = () => {
    const daySchedule = globalData.schedule.find(day => day.daysOfWeek === currentDay);

    if (daySchedule) {
      if (daySchedule.open) {
        const morningSchedule = `${formatTime(daySchedule.openingTime)} - ${formatTime(daySchedule.breakStart)}`;
        const afternoonSchedule = `${formatTime(daySchedule.breakEnd)} - ${formatTime(daySchedule.closingTime)}`;

        // Check screen width to decide separator


        setMorningSchedule(morningSchedule)
        setAfternoonSchedule(afternoonSchedule)

      } else {
        setMorningSchedule('Cerrado')
      }
    }

    return 'Horario no disponible';
  };

  const formatPhoneNumber = (phone) => {
    // Remove all non-numeric characters
    const cleaned = phone.replace(/\D/g, '');

    if (cleaned.length == 13) {
      const match = cleaned.match(/^(\d{2})(\d{1})(\d{3})(\d{3})(\d{4})$/);
      return match ? `(${match[3]}) ${match[4]} ${match[5]}` : phone;
    } else if (cleaned.length == 10) {
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      return match ? `(${match[1]}) ${match[2]} ${match[3]}` : phone;
    }
    return phone;
  }




  return (
    <Container>
      <Main>
        <Card href={`mailto:${contactEmail}`}>Email: {contactEmail} <StaticChevronIcon
          icon={faChevronRight}
        /></Card>
        <Card href={`tel:${phoneNumber}`}>Teléfono: {formatPhoneNumber(phoneNumber)} <StaticChevronIcon
          icon={faChevronRight}
        /></Card>

        {/* Adjust ul styling */}
        <Accordion>
          <li className={expandedSection === 0 ? 'ci-expanded' : ''}>
            <button
              type="button"
              onClick={() => toggleSection(0)}
              style={buttonStyle}
            >
              <>
                {`${currentDay}:`}
                <ScheduleText >
                  <span>
                    {window.innerWidth >= 600 && '\u00A0'}
                    {`${morningSchedule}`}
                  </span>
                  {afternoonSchedule ? window.innerWidth >= 600 && "|" : null}
                  <span>
                    {`${afternoonSchedule || ''
                      }`}
                  </span>
                </ScheduleText>
              </>
              <ChevronIcon
                icon={faChevronRight}
                isExpanded={expandedSection === 0}
              />
            </button>

            <section
              className={`ci-content ${expandedSection === 0 ? 'ci-visible' : ''}`}
              id="acc0"
              aria-labelledby="accLink0"
              tabIndex="0"
            >
              {expandedSection === 0 && (
                <ResponseContainer>
                  <p style={{ fontWeight: 'bold', marginBottom: '20px' }}>{scheduleDescription}</p>
                  <CustomTable>
                    <thead>
                      <tr>
                        <th>Day</th>
                        <th>Horario mañana</th>
                        <th>Horario tarde</th>
                      </tr>
                    </thead>
                    <tbody>
                      {schedule.map((day, index) => (
                        <tr key={index}>
                          <td>{day.daysOfWeek}</td>

                          {day.open ? (
                            <td>{formatTime(day.openingTime)}-
                              {formatTime(day.breakStart)}</td>
                          ) : (<td>{formatTime(day.openingTime)}</td>)}
                          {day.open ? (
                            <td>{formatTime(day.breakEnd)}-
                              {formatTime(day.closingTime)}</td>
                          ) : <td> {formatTime(day.breakEnd)}</td>}


                        </tr>
                      ))}
                    </tbody>
                  </CustomTable>
                </ResponseContainer>
              )}
            </section>
          </li>
        </Accordion>

        <Card href={addressLink.Link} target="_blank" rel="noopener noreferrer"> {addressLink.Name} <StaticChevronIcon
          icon={faChevronRight}
        /></Card>
      </Main>
      <LottieStyled animationData={ClimbingShoes} loop={true} />
    </Container>
  );
};

const buttonStyle = {
  boxSizing: 'border-box',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '1rem',
  padding: '10px 20px',
  color: '#151618',
  cursor: 'pointer',
  background: 'none',
  border: 'none',
  textAlign: 'left',
  backgroundColor: '#f6f6f6',
  [`@media (max-width:600px)`]: {
    // flexDirection: 'column',
    // justifyContent: 'center',
  },
};

const LottieStyled = styled(Lottie)(() => ({
  maxWidth: '400px',
  [`@media (max-width:600px)`]: {
    maxWidth: '200px',
  },
}));

const ResponseContainer = styled('div')(() => ({
  padding: 10,
}))

const CustomTable = styled('table')(() => ({
  boxSizing: 'border-box',
  width: '100%',
  borderCollapse: 'collapse',
  [`@media (max-width:600px)`]: {
    fontSize: 14,
  },
}))

const ChevronIcon = styled(FontAwesomeIcon)(({ isExpanded }) => ({
  marginLeft: 40,
  color: '#2cdce6',
  transition: 'transform 0.3s ease',
  transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
}));

const StaticChevronIcon = styled(FontAwesomeIcon)(() => ({
  marginLeft: 40,
  color: '#2cdce6',
  transition: 'transform 0.3s ease',
}));

const Container = styled('div')(() => ({
  width: '100%',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 100,
  [`@media (max-width:600px)`]: {
    gap: 10,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '30px 0',
  },
}));

// Styling for the ul element to match card width
const Accordion = styled('ul')(() => ({
  width: '100%',
  // backgroundColor: 'pink',
  maxWidth: '600px',  // Same as cards
  listStyleType: 'none',
  padding: 0,
  margin: 0,


  [`@media (max-width:600px)`]: {
    padding: '10px 20px',
    // maxWidth: '350px',  // Same as cards on mobile
  },
}));

const Card = styled('a')({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#f6f6f6',
  width: '100%',
  borderRadius: '1rem',
  padding: '10px 20px',
  color: '#151618',
  textDecoration: 'none',
  cursor: 'pointer',
  maxWidth: '600px',
  '&:hover': {
    backgroundColor: '#e0e0e0',
  },
  [`@media (max-width:600px)`]: {
    width: '350px',
  },
});

const Main = styled('main')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 20,
}));

const ScheduleText = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 10,
  [`@media (max-width:600px)`]: {
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 0,
  },
}))

export default ContactInfo;
