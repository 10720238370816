import React, { createContext, useState, useEffect } from 'react';
import { getGlobal } from '../api/global';

export const GlobalDataContext = createContext(null);

const GlobalDataProvider = ({ children }) => {
  const [globalData, setGlobalData] = useState(null);

  useEffect(() => {
    const fetchGlobalData = async () => {
      try {
        const response = await getGlobal();

        if (response.data.attributes) {
          setGlobalData(response.data.attributes); // Assuming the structure from your response
        } else {
          console.error('Context fetch failed');
        }
      } catch (error) {
        console.error('Failed to fetch global data:', error);
      }
    };

    fetchGlobalData();
  }, []);

  return (
    <GlobalDataContext.Provider value={globalData}>
      {children}
    </GlobalDataContext.Provider>
  );
};

export default GlobalDataProvider;
